import Swiper from 'swiper';

window.addEventListener('load', function () {
  const carousels = document.querySelectorAll('.cta-carousel');

  if (0 < carousels.length) {
    carousels.forEach(function (carousel) {
      new Swiper(carousel, {
        slidesPerView: 1.3,
        slideClass: 'cta-page-block',
        spaceBetween: 25,
        scrollbar: {
          el: carousel.querySelector('.swiper-scrollbar'),
          draggable: true,
        },
        navigation: {
          prevEl: carousel.querySelector('.swiper-button-prev'),
          nextEl: carousel.querySelector('.swiper-button-next'),
        },
        breakpoints: {
          540: {
            slidesPerView: 2.4,
          },
          920: {
            slidesPerView: 3.1,
          },
          1600: {
            slidesPerView: 3.79,
          },
        },
      });
    });
  }
});
